import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
Injectable();
export class CertificateTemplateService {
  public academicPeriodOptions: any;
  public academicPeriodOptionsSub = new Subject<any>();
  public filterSub = new Subject<any>();
  public tabsObj: any;
  public marksId;
  // public forecastId;
  public componentId;
  public overViewFilter = { academic_period_id: null };
  public importDataList: any;
  public forecastId: any;
  public forecastsData: any;
  constructor() {}

  public AcademicPeriods() {}

  public setAcademicPeriods(academic_period_options) {
    this.academicPeriodOptions = academic_period_options;
    this.academicPeriodOptionsSub.next([...academic_period_options]);
  }

  public setFiler(data) {
    console.log("The set filter data", data);
    this.filterSub.next({ ...data });
  }

  public getAcademicPeriods() {
    return this.academicPeriodOptions;
  }

  public setTabObject(nameIdObj) {
    this.tabsObj = nameIdObj;
    console.log(this.tabsObj);
  }

  getImportDetail() {
    return this.importDataList;
  }

  public getTabObject() {
    console.log(this.tabsObj);
    return this.tabsObj;
  }

  public forgetFilterValues() {
    this.overViewFilter = { academic_period_id: null };
  }

  public saveComponentFilter(tabName, filterName, value) {
    this.overViewFilter[filterName] = value;
  }
}
