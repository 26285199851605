const ROUTER_BASE_PATH: string = "/main/items/item/view";

interface IKdTabs {
  tabName?: string;
  tabContent?: string;
  tabId?: string;
  isActive?: boolean;
  disabled?: boolean;
  routerPath?: string;
}
export const TABS_ROUTER: Array<IKdTabs> = [

  {
    tabName: 'Detail',
    routerPath: ROUTER_BASE_PATH ,
    isActive: true,
  },
  {
    tabName: 'Responses',
    routerPath: ROUTER_BASE_PATH + '/responses'
  },
  {
    tabName: 'Statistics',
    routerPath: ROUTER_BASE_PATH + '/statistics'
  },
  {
    tabName: 'Syllabus',
    routerPath: ROUTER_BASE_PATH + '/syllabuss'
  },
  {
    tabName: 'Comments',
    routerPath: ROUTER_BASE_PATH + '/comments'
  },
  // {
  //   tabName: 'Linked Institutions',
  //   routerPath: ROUTER_BASE_PATH + '/linkedinstitutions'
  // },
]