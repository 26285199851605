import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { KdAlertEvent } from 'openemis-styleguide-lib';
import { AuthService } from './auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  // private userDetails:any;

  constructor(public router: Router, private kdAlertEvent: KdAlertEvent, private authSvc: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((res)=>{
      if (route.data.page === 'auth') {
        if (!this.isLoggedIn()) {
          res(true);
        } else {
          this.toasterCall();
          this.router.navigate(['/main']);
          res(false);
        }
      // } else if (route.data.page === 'main') {

      //   if (this.isLoggedIn()) {
      //     res(true);
      //   } else {
      //     this.toasterCall();
      //     this.router.navigate(['/auth']);
      //     res(false);
      //   }

      /* This code is commented because it was exactly similar to the code in else case below. */
      
      } else {

        if (this.isLoggedIn()) {
          this.checkTokenValidity().then((suc) =>{
            res(true);
          },(fail)=>{
            this.toasterCall();
            this.router.navigate(['/auth']);
            res(false);
          })
        } else {
          this.toasterCall();
          this.router.navigate(['/auth']);
          res(false);
        }
      }
    });
  }

  isLoggedIn() {
    let accessToken: any;
    if (localStorage.getItem('token')) {
      accessToken = JSON.parse(window.atob(localStorage.getItem('token')));
      // this.userDetails = JSON.parse(localStorage.getItem('userInfo'));
    }
    return accessToken ? true : false;
  }

  checkTokenValidity(){

    return new Promise((resolve, reject)=>{
      this.authSvc.fetchPermissionsList().subscribe((res)=>{
        this.authSvc.setPermissionsList(res);
        resolve(true);
      }, (err)=>{
        localStorage.clear();
        reject(false);
      })
    })
  }

  toasterCall(){
    let toasterConfig: any = {
      title: 'Unauthorised Access',
      body: '',
      showCloseButton: true,
      tapToDismiss: false
    };
    this.kdAlertEvent.error(toasterConfig);
  }
}
