const ROUTER_BASE_PATH: string = "/main/examinations/centres/view";

interface IKdTabs {
  tabName?: string;
  tabContent?: string;
  tabId?: string;
  isActive?: boolean;
  disabled?: boolean;
  routerPath?: string;
}
export const TABS_ROUTER: Array<IKdTabs> = [

  {
    tabName: 'Overview',
    routerPath: ROUTER_BASE_PATH ,
    isActive: true,
  },
  {
    tabName: 'Rooms',
    routerPath: ROUTER_BASE_PATH + '/rooms'
  },
  // {
  //   tabName: 'Examinations',
  //   routerPath: ROUTER_BASE_PATH + '/examinations'
  // },
  {
    tabName: 'Subjects',
    routerPath: ROUTER_BASE_PATH + '/subjects'
  },
  {
    tabName: 'Students',
    routerPath: ROUTER_BASE_PATH + '/students'
  },
  {
    tabName: 'Invigilators',
    routerPath: ROUTER_BASE_PATH + '/invigilators'
  },
  // {
  //   tabName: 'Linked Institutions',
  //   routerPath: ROUTER_BASE_PATH + '/linkedinstitutions'
  // },
]