export interface ITreeConfig {
  id: string;
  selectionMode: string;
  list?: object;
  expandAll?: boolean;
  expandSelected?: boolean;
}

export const TREE_CONFIG_AREA: ITreeConfig = {
  id: "areaName",
  list: [],
  selectionMode: "single",
};

export const TREE_CONFIG_ADDRESS: ITreeConfig = {
  id: "addressArea",
  list: [],
  selectionMode: "single",
};

export const TREE_CONFIG_BIRTH: ITreeConfig = {
  id: "birthplaceArea",
  list: [],
  selectionMode: "single",
};

interface TableColumns {
  option_id?: any;
  option_code?: any;
  option_name?: any;
  carry_forward?: any;
}

const COLUMN_OPTION_ID: any = {
  headerName: "Option Code",
  field: "option_code",
  sortable: false,
  filterable: false,
  visible: true,
  class: "ag-id",
};
const COLUMN_OPTION_CODE: any = {
  headerName: "Option ID",
  field: "option_id",
  sortable: false,
  filterable: false,
  visible: false,
  class: "ag-id",
};

const COLUMN_OPTION_NAME: any = {
  headerName: "Option Name",
  field: "option_name",
  sortable: false,
  filterable: false,
  visible: true,
};

const COLUMN_OPTION_CARRY: any = {
  headerName: "Carry Forward",
  field: "carry_forward",
  sortable: false,
  filterable: false,
  visible: true,
};

export const TABLE_COLUMN_LIST: TableColumns = {
  option_id: COLUMN_OPTION_ID,
  option_code: COLUMN_OPTION_CODE,
  option_name: COLUMN_OPTION_NAME,
  carry_forward: COLUMN_OPTION_CARRY,
};
