import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CentreService {
  public importValue:any=null
  public centerId: any;
  public centreDetail: any;
  public centreRoomId: any;
  public centreInvigilatorsDetail: any;
  public subjecjViewDetail: any;
  public studentViewId: any;
  public centerImportDetail:any;
  public SelectDropValue:any;
  public academic_period_id:any;
  public examination_id:any;
  constructor() {}

  setCentreId(data) {
    this.centerId = data;
  }

  getCentreId() {
    return this.centerId;
  }
  setCentreDetail(data) {
    this.centreDetail = data;
  }

  getCentreDetail() {
    return this.centreDetail;
  }

  setCentreRoomId(data) {
    this.centreRoomId = data;
  }

  getCentreRoomId() {
    return this.centreRoomId;
  }

  setInvigilatorDetail(data) {
    this.centreInvigilatorsDetail = data;
  }

  getInvigilatorDetail() {
    return this.centreInvigilatorsDetail;
  }

  setSubjectView(data) {
    this.subjecjViewDetail = data;
  }

  getSubjectView() {
    return this.subjecjViewDetail;
  }

  setStudentView(data) {
    this.studentViewId = data;
  }

  getStudentView() {
    return this.studentViewId;
  }

  setImportDetail(data){
    this.centerImportDetail=data;
  }

  getImportDetail(){
     return this.centerImportDetail;
  }

  setAcademicId(academic_id){
    this.academic_period_id=academic_id;
  }

  setExaminationId(exam_id){
    this.examination_id=exam_id;
  }

}
