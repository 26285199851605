import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import urls from "../../../shared/config.urls";

@Injectable()
export class FinalGradesDataService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  /*** Add token to request ***/
  setHeader(): any {
    if (localStorage.getItem("token")) {
      let token = JSON.parse(window.atob(localStorage.getItem("token")));
      let headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
      return { headers: headers };
    } else {
      let headers = new HttpHeaders({});
      return { headers: headers };
    }
  }

  /*** LIST | API  ***/
  getList(
    academic_period_id,
    examination_id,
    examination_centre_id,
    option_id,
    start,
    end,
    keyword?
  ) {
    if (keyword) {
      return this.httpClient
        .get(
          `${environment.baseUrl}/${urls.mulitpleChoiceList}?academic_period_id=${academic_period_id}&examination_id=${examination_id}&examination_centre_id=${examination_centre_id}&options_id=${option_id}&start=${start}&end=${end}&keyword=${keyword}`,
          this.setHeader()
        )
        .pipe(catchError(this.handleError));
    } else {
      return this.httpClient
        .get(
          `${environment.baseUrl}/${urls.mulitpleChoiceList}?academic_period_id=${academic_period_id}&examination_id=${examination_id}&examination_centre_id=${examination_centre_id}&options_id=${option_id}&start=${start}&end=${end}`,
          this.setHeader()
        )
        .pipe(catchError(this.handleError));
    }
  }

  /***  LIST SEARCH ***/
  searchList(
    academic_period_id,
    examination_id,
    examination_centre_id,
    keyword
  ) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.mulitpleChoiceList}?academic_period_id=${academic_period_id}&examination_id=${examination_id}&examination_centre_id=${examination_centre_id}&keyword=${keyword}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /*** VIEW PAGE ***/
  getDetails(candidateId: string) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/results/final-grade/view/${candidateId}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  getExaminationOpton(candidateId, optionId) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/results/final-grade/innerView/${candidateId}/${optionId}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /*** GENERATE RESULTS ***/
  generateFinalGrades(data) {
    return this.httpClient
      .post(
        `${environment.baseUrl}/${urls.results}/${urls.finalGrades}/generate?examination_centre_id=${data.examination_centre_id}&examination_id=${data.examination_id}&academic_period_id=${data.academic_period_id}&option_id=${data.options_id}&compId=${data.compid}&candidate_id=${data.candidate_id}`,
        {},
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /*** Handle Http Errors ***/
  handleError = (error: HttpErrorResponse | any) => {
    switch (error.status) {
      case 400:
        console.log(400);
        break;
      case 401:
        console.log(401);
        let temp = localStorage.getItem("token");
        if (temp) {
          localStorage.clear();
          this.router.navigate(["/auth"]);
        }
        break;
      case 500:
        console.log(500);
        break;
      case 503:
        console.log(503);
        break;
    }
    console.log(error);
    return throwError(error);
  };

  /*** IMPORT TEMPLATE API  ***/

  getFinalGradesImportTemplate(examId, option_id) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/marks/${urls.component}/final-template/examId/${examId}/optionId/${option_id}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

    /*** IMPORT PAGE ***/
    gradeImport(data) {
      return this.httpClient
        .post(
          `${environment.baseUrl}/results/final-grade/import`,
          data,
          this.setHeader()
        )
        .pipe(catchError(this.handleError));
    }
}
