import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import urls from '../../../../../shared/config.urls';

@Injectable()
export class UsersViewDataService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  /** Add token to request **/
  setHeader(): any {
    if (localStorage.getItem('token')) {
      let token = JSON.parse(window.atob(localStorage.getItem('token')));
      let headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
      return { headers: headers };
    } else {
      let headers = new HttpHeaders({});
      return { headers: headers };
    }
  }

  /**  DETAILS | VIEW  API  **/
  getDetailsView(openemis_id) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${openemis_id}/${urls.userDetails}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /**  DETAILS | VIEW  API  **/
  getDetailsEdit(openemis_id) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${openemis_id}/${urls.userDetailsEdit}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /**  DETAILS | EDIT  API  **/
  updateUserDetails(openemis_id, payload) {
    return this.httpClient
      .post(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${openemis_id}/${urls.userUpdate}`,
        payload,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /**  ACCOUNTS | VIEW  API  **/
  getAccountsView(openemis_id) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${openemis_id}/${urls.userAccountView}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /**  ACCOUNTS | EDIT  API  **/
  updateAccountDetails(openemis_id, payload) {
    return this.httpClient
      .post(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${openemis_id}/${urls.userAccountEdit}`,
        payload,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /* Handle Http Errors */
  handleError = (error: HttpErrorResponse | any) => {
    switch (error.status) {
      case 400:
        console.log(400);
        break;
      case 401:
        console.log(401);
        let temp = localStorage.getItem('token');
        if (temp) {
          localStorage.clear();
          this.router.navigate(['/auth']);
        }
        break;
      case 500:
        console.log(500);
        break;
      case 503:
        console.log(503);
        break;
    }
    console.log(error);
    return throwError(error);
  };
}
