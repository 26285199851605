import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from ".././../../environments/environment";
import urls from "../../shared/config.urls";

@Injectable()
export class LandingService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  /*** Add token to request ***/
  setHeader(): any {
    if (localStorage.getItem("token")) {
      let token = JSON.parse(window.atob(localStorage.getItem("token")));
      let headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
      return { headers: headers };
    } else {
      let headers = new HttpHeaders({});
      return { headers: headers };
    }
  }

  /***  NOTICES LIST ***/
  // getNoticeList() {
  //     return this.httpClient
  //     .get(
  //         `${environment.baseUrl}/home/notices`,
  //         this.setHeader()
  //     )
  //     .pipe(catchError(this.handleError));
  // }
  // ${environment.baseUrl}/${urls.administration}/${urls.systemSetup}/${urls.notices}/list?start=${start}&end=${end}

  getNoticeList() {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.administration}/${urls.systemSetup}/${
          urls.notices
        }/list?start=${0}&end=${100}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  getExamStats() {
    return this.httpClient
      .get(`${environment.baseUrl}/home/exam-statistics`, this.setHeader())
      .pipe(catchError(this.handleError));
  }

  /*** Handle Http Errors ***/
  handleError = (error: HttpErrorResponse | any) => {
    switch (error.status) {
      case 400:
        console.log(400);
        break;
      case 401:
        console.log(401);
        let temp = localStorage.getItem("token");
        if (temp) {
          localStorage.clear();
          this.router.navigate(["/auth"]);
        }
        break;
      case 500:
        console.log(500);
        break;
      case 503:
        console.log(503);
        break;
    }

    return throwError(error);
  };
}
