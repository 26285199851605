import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UsersDataService } from './users-data.service';

@Injectable()
export class UsersService {
  private openEmisId: any;
  public openEmisIdChanged = new BehaviorSubject<number>(this.openEmisId);
  private userName: string;
  public userNameChanged = new BehaviorSubject<string>(this.userName);
  constructor(private usersDataService: UsersDataService) {}

  /*** Set OpenEMIS id */
  setOpenEmisId(id: any): void {
    this.openEmisId = id;
    this.openEmisIdChanged.next(this.openEmisId);
  }

  /***Set UserName */
  setUserName(name: any): void {
    this.userName = name;
    this.userNameChanged.next(this.userName);
  }

  /*** USERS List Data ***/
  getUsersList(start?: number, end?: number) {
    return this.usersDataService.getList(start, end);
  }

  /* Dropdown for gender,nationality,identity type options*/
  getDropdownOptions(entity) {
    return this.usersDataService.getDropdownOptions(entity);
  }

  /* Dropdown for gender,nationality,identity type options*/
  getGenderDropDownOptions(entity) {
    return this.usersDataService.getGenderDropdownOptions(entity);
  }

  /*** USERS List Data | SEARCH***/
  getUsersListSearch(keyword: string, start?: number, end?: number) {
    return this.usersDataService.getListSearch(keyword, start, end);
  }

  /*** USERS Add generate openEMIS Id***/
  generateOpenEmisID() {
    return this.usersDataService.generateOpenEMISID();
  }

  /*** USERS Add ***/
  addUser(payload) {
    return this.usersDataService.addUser(payload);
  }
}
