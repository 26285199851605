import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

import { SharedService } from '../../../../../shared/shared.service';
import { saveFail, saveSuccess, copySuccess } from '../../../../../shared/shared.toasters';
import { ExamsService } from '../exams.service';

@Injectable()
export class ExamsListService {
  private examsList: Array<any>;
  public examsListChanged = new BehaviorSubject<any>(null);
  private year: any;
  public yearChanged = new Subject();
  private exam: any;
  public examChanged = new Subject();
  private educationProgramme: any;
  public educationProgrammeChanged = new Subject();
  private educationGrade: any;
  public educationGradeChanged = new Subject();
  private examType: any;
  public examTypeChanged = new Subject();
  private examSession: any;
  public examSessionChanged = new Subject();
  private attendanceType: any;
  public attendanceTypeChanged = new Subject();

  constructor(private examsService: ExamsService, private sharedService: SharedService, private _router: Router) {}

  getExamsList(start?: number, end?: number) {
    return this.examsService.getExamsList(start, end);
  }

  getExamsListSearch(keyword: string, start?: number, end?: number) {
    return this.examsService.getExamsListSearch(keyword, start, end);
  }

  /*** ADD PAGE | EDUCATION PROGRAMME DROPDOWN ***/
  getEducationProgramme() {
    this.examsService.getEducationProgramme().subscribe(
      (res: any) => {
        if (res.data.length) {
          this.educationProgramme = res.data;
          this.educationProgrammeChanged.next([...this.educationProgramme]);
        } else {
          this.educationProgramme = [];
          this.educationProgrammeChanged.next([...this.educationProgramme]);
        }
      },
      (err) => {
        this.educationProgramme = [];
        this.educationProgrammeChanged.next([...this.educationProgramme]);
      }
    );
  }

  /*** ADD PAGE | EDUCATION GRADE DROPDOWN ***/
  getEducationGrade(education_programme_id) {
    this.examsService.getEducationGrade(education_programme_id).subscribe(
      (res: any) => {
        if (res.data.length) {
          this.educationGrade = res.data;
          this.educationGradeChanged.next([...this.educationGrade]);
        } else {
          this.educationGrade = [];
          this.educationGradeChanged.next([...this.educationGrade]);
        }
      },
      (err) => {
        this.educationGrade = [];
        this.educationGradeChanged.next([...this.educationGrade]);
      }
    );
  }

  /*** ADD PAGE | EXAM TYPE DROPDOWN ***/
  getExamType() {
    this.examsService.getExamType().subscribe(
      (res: any) => {
        if (res.data.length) {
          this.examType = res.data;
          this.examTypeChanged.next([...this.examType]);
        } else {
          this.examType = [];
          this.examTypeChanged.next([...this.examType]);
        }
      },
      (err) => {
        this.examType = [];
        this.examTypeChanged.next([...this.examType]);
      }
    );
  }

  /*** ADD PAGE | EXAM SESSION DROPDOWN ***/
  getExamSession() {
    this.examsService.getExamSession().subscribe(
      (res: any) => {
        if (res.data.length) {
          this.examSession = res.data;
          this.examSessionChanged.next([...this.examSession]);
        } else {
          this.examSession = [];
          this.examSessionChanged.next([...this.examSession]);
        }
      },
      (err) => {
        this.examSession = [];
        this.examSessionChanged.next([...this.examSession]);
      }
    );
  }

  /*** ADD PAGE | Year***/
  getOptionsAcademicPeriod() {
    let data = 'academicPeriods';
    this.examsService.getOptionsAcademicPeriod(data).subscribe(
      (res: any) => {
        if (res && res.data && res.data.academic_period_id) {
          this.year = res.data.academic_period_id;
          this.yearChanged.next([...this.year]);
        } else {
          this.year = [];
          this.yearChanged.next([...this.year]);
        }
      },
      (err) => {
        this.year = [];
        this.yearChanged.next([...this.year]);
      }
    );
  }

  /*** ADD PAGE | ATTENDANCE TYPE  DROPDOWN ***/
  getAttendanceType(entities) {
    this.examsService.getAttendanceTypeDropdown(entities).subscribe(
      (res: any) => {
        if (res.data.examination_attendance_types_id.length) {
          this.attendanceType = res.data.examination_attendance_types_id;
          this.attendanceTypeChanged.next([...this.attendanceType]);
        } else {
          this.attendanceType = [];
          this.attendanceTypeChanged.next([...this.attendanceType]);
        }
      },
      (err) => {
        this.attendanceType = [];
        this.attendanceTypeChanged.next([...this.attendanceType]);
      }
    );
  }

  /*** ADD ***/
  addExamDetails(data) {
    return this.examsService.addExamDetails(data).subscribe(
      (data: any) => {
        if (data) {
          this.sharedService.setToaster(saveSuccess);
          this._router.navigate(['main/examinations/exams/list']);
        } else {
          this.sharedService.setToaster(saveFail);
        }
      },
      (err) => {
        this.sharedService.setToaster(saveFail);
      }
    );
  }

  /***  Exam  DropDown***/
  getExamOptions(academic_id) {
    this.examsService.getExamOptions(academic_id).subscribe(
      (res: any) => {
        if (res && res.data.length) {
          this.exam = res.data;
          this.examChanged.next([...this.exam]);
        } else {
          this.exam = [];
          this.examChanged.next([...this.exam]);
        }
      },
      (err) => {
        this.exam = [];
        this.examChanged.next([...this.exam]);
      }
    );
  }

  /*** COPY ***/
  copyExamDetails(data) {
    return this.examsService.copyExamDetails(data).subscribe(
      (data: any) => {
        if (data) {
          this.sharedService.setToaster(copySuccess);
          this._router.navigate(['main/examinations/exams/list']);
        } else {
          this.sharedService.setToaster(saveFail);
        }
      },
      (err) => {
        this.sharedService.setToaster(saveFail);
      }
    );
  }
}
