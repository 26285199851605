const ROUTER_BASE_PATH: string = "/main/items/tests/view";

interface IKdTabs {
  tabName?: string;
  tabContent?: string;
  tabId?: string;
  isActive?: boolean;
  disabled?: boolean;
  routerPath?: string;
}
export const TABS_ROUTER: Array<IKdTabs> = [

  {
    tabName: 'Detail',
    routerPath: ROUTER_BASE_PATH ,
    isActive: true,
  },
  {
    tabName: 'Items',
    routerPath: ROUTER_BASE_PATH + '/itemss'
  },
]