import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

Injectable()
export class ReviewCriteriaService {
    
    public academicPeriodOptions: any;
    public academicPeriodOptionsSub = new Subject<any>();
    public tabsObj: any;

    public marksId;
    // public forecastId;
    public componentId;
    public componentFilters = {academic_period_id: null, examination_id: null};
    public marksFilters = {academic_period_id: null, examination_id: null};
    public forecastFilters = {academic_period_id: null, examination_id: null};
    public importDataList: any;
    public forecastId: any;
    public forecastsData: any;
    constructor() {}

    public setAcademicPeriods(academic_period_options){
        this.academicPeriodOptions = academic_period_options;
        this.academicPeriodOptionsSub.next(academic_period_options);
    }
    
    public getAcademicPeriods(){
        return this.academicPeriodOptions;
    }
    public setTabObject(nameIdObj){
        this.tabsObj = nameIdObj;
        console.log(this.tabsObj);

    }
    getImportDetail() {
        return this.importDataList;
      }
    
    public getTabObject(){
        console.log(this.tabsObj);
        return this.tabsObj;
    }

    public forgetFilterValues(){
        this.componentFilters = {academic_period_id: null, examination_id: null};
        this.marksFilters = {academic_period_id: null, examination_id: null};
        this.forecastFilters = {academic_period_id: null, examination_id: null};
    }

    public saveComponentFilter(tabName ,filterName, value){
        
        switch(tabName){
            case 'component':
                this.componentFilters[filterName] = value;
            break;

            case 'marks':
                this.marksFilters[filterName] = value;
            break;

            case 'forecast':
                this.forecastFilters[filterName] = value;
            break;
        }
    }
    
    public setMarksId(marksId){
        this.marksId = marksId;
    }

    public getMarksId(){
        return this.marksId;
    }

    public setForecastId(forecastId){
        this.forecastId = forecastId;
    }

    public getForecastId(){
        return this.forecastId;
    }

    public setComponentId(componentId){
        this.componentId = componentId;
    }

    public getComponentId(){
        return this.componentId;
    }
    public setImportDetail(data){
        this.importDataList=data;
      }
    
    
      public setforecastsDataForEdit(forecasts_data){
        this.forecastsData = forecasts_data;
      }
    
      public getforecastsDataForEdit(){
        return this.forecastsData;
      }
      public setComponentsDataForEdit(forecasts_data){
        this.forecastsData = forecasts_data;
      }
    
      public getComponentsDataForEdit(){
        return this.forecastsData;
      }
      public setMarksDataForEdit(forecasts_data){
        this.forecastsData = forecasts_data;
      }
    
      public getMarksDataForEdit(){
        return this.forecastsData;
      }

}