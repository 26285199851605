import { Component, OnInit } from "@angular/core";
import { LEFT_NAV } from "./shared/config.left-nav";
import { DEFAULT_TEMPLATE } from "./shared/config.default-val";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
