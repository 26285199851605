import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

import { ComponentsService } from "../components.service";

@Injectable()
export class ListService {
  private currentFilters: any = {};
  public currentFiltersChanged = new BehaviorSubject<any>(this.currentFilters);

  public academic_period_id: any;
  public examination_id: any;
  public examination_centre_id: any;
  public option_id: any;
  public componentId: number;
  constructor(private componentService: ComponentsService) {}
  /*** SET Current filters ***/
  setCurrentFilters(key: string, value: Object) {
    this.currentFilters[key] = value;
    this.currentFiltersChanged.next({ ...this.currentFilters });
    this.componentService.setValues({ ...this.currentFilters });
  }

  /*** Empty Current filters ***/
  emptyCurrentFilters() {
    this.currentFilters = {};
    this.currentFiltersChanged.next({ ...this.currentFilters });
  }

  setAcademicPeriodId(academic_id) {
    this.academic_period_id = academic_id;
  }

  setExaminationId(exam_id) {
    this.examination_id = exam_id;
  }

  setExaminationCentreId(centre_id) {
    this.examination_centre_id = centre_id;
  }

  setOptions(option_id) {
    this.option_id = option_id;
  }

  setComponentId(comp_id) {
    this.componentId = comp_id;
  }
}
