import { ITableColumn } from 'openemis-styleguide-lib';

export const COLUMN_SUBJECT_CODE: ITableColumn = {
  headerName: 'Subject Code',
  field: 'subject_code',
  sortable: false,
  filterable: false,
  // filterValue: [],
  visible: true
};

export const COLUMN_SUBJECT_NAME: ITableColumn = {
  headerName: 'Subject Name',
  field: 'subject_name',
  sortable: false,
  filterable: false,
  // filterValue: [],
  visible: true
};

export const TABLE_COLUMN_LIST = {
  subject_code: COLUMN_SUBJECT_CODE,
  subject_name: COLUMN_SUBJECT_NAME
};
