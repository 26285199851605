export const PAGE_SIZE = 20;

export const DEFAULT_OPTIONS_EMPTY = [{ key: null, value: '-- Select --' }];

export const DEFAULT_OPTIONS_YES_NO = [
  { key: null, value: '-- Select --' },
  { key: 1, value: 'Yes' },
  { key: 0, value: 'No' }
];

export const EXCLUDE_INCLUDE = [
  { key: null, value: '-- Select --' },
  { key: 0, value: 'Exclude' },
  { key: 1, value: 'Include' }
];