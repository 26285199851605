import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

import { DataService } from "../../../shared/data.service";

@Injectable()
export class ComponentsService {
  private data: any = {};
  public dataChanged = new BehaviorSubject<any>(this.data);
  private typeDropdown: any;
  public typeDropdownChanged = new Subject();
  private statusDropDown: any;
  public statusDropDownChanged = new Subject();
  public grade: any;

  public componentId = new BehaviorSubject<number>(null);
  public compoentIdAndStudentId = new BehaviorSubject<any>({});

  constructor(private dataService: DataService) {}

  setValues(data) {
    this.data = data;
    this.dataChanged.next({ ...this.data });
    // console.log("Component data", { ...this.data });
  }

  public getStatusDropdown() {
    this.dataService.getStatusMarksComponent().subscribe((res: any) => {
      if (res.data.length) {
        this.statusDropDown = res.data;
        this.statusDropDownChanged.next([...this.statusDropDown]);
      }
    });
  }

  public getTypeDropdown(id) {
    this.dataService.getTypeMarksComponent(id).subscribe((res: any) => {
      if (res.data.length) {
        this.typeDropdown = res.data;
        this.typeDropdownChanged.next([...this.typeDropdown]);
      }
    });
  }
  // MaxRawMarks(marks_obj) {
  //   this.max_raw_marks = marks_obj;
  //   console.log(this.max_raw_marks);
  //   return this.max_raw_marks;

  // }
  GenerateGrade(grade_obj) {
    this.grade = grade_obj;
    // console.log(this.grade);
    return this.grade;
  }

  setComponentId(id: any) {
    let compid = { ...id, ...id };
    this.compoentIdAndStudentId.next(compid);
  }
}
