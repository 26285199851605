import { IDropdownOptions } from '../../../../../../shared/shared.interfaces';

const FILTER_OPTIONS: Array<IDropdownOptions> = [
  { key: 'subjects', value: 'Subjects' },
  { key: 'stages', value: 'Stages' },
  { key: 'certifications', value: 'Certifications' },
  { key: 'field_of_studies', value: 'Field of Studies' },
  { key: 'programme_orientations', value: 'Programme Orientations' }
];

export const TABLE_COLUMNS: any = {
  subjects: [
    {
      headerName: 'Visible',
      field: 'visible',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Code',
      field: 'code',
      sortable: true,
      filterable: true,
      filterValue: []
    }
    // {
    //   headerName: 'Reorder',
    //   field: 'reorder',
    //   sortable: false,
    //   filterable: false
    // }
  ],
  stages: [
    {
      headerName: 'Visible',
      field: 'visible',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Code',
      field: 'code',
      sortable: true,
      filterable: true,
      filterValue: []
    }
    //, {
    //   headerName: 'Reorder',
    //   field: 'reorder',
    //   sortable: false,
    //   filterable: false
    // }
  ],
  certifications: [
    {
      headerName: 'Visible',
      field: 'visible',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: true,
      filterValue: []
    }
    //, {
    //   headerName: 'Reorder',
    //   field: 'reorder',
    //   sortable: false,
    //   filterable: false
    // }
  ],
  field_of_studies: [
    {
      headerName: 'Visible',
      field: 'visible',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Education Programme Orientation',
      field: 'education_programme_orientation',
      sortable: true,
      filterable: true,
      filterValue: []
    }
    //, {
    //   headerName: 'Reorder',
    //   field: 'reorder',
    //   sortable: false,
    //   filterable: false
    // }
  ],
  programme_orientations: [
    {
      headerName: 'Visible',
      field: 'visible',
      sortable: true,
      filterable: true,
      filterValue: []
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: true,
      filterValue: []
    }
    //, {
    //   headerName: 'Reorder',
    //   field: 'reorder',
    //   sortable: false,
    //   filterable: false
    // }
  ]
};

export const FILTER_INPUTS: Array<any> = [
  {
    key: 'setup_item',
    visible: true,
    required: true,
    order: 1,
    controlType: 'dropdown',
    options: [...FILTER_OPTIONS],
    events: true
  }
];
