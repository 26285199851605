import { Injectable } from '@angular/core';

import { UsersViewDataService } from './users-view-data.service';

@Injectable()
export class UsersViewService {
  constructor(private usersViewDataService: UsersViewDataService) {}

  /**  DETAILS | VIEW  API  **/
  getDetailsView(openemis_id) {
    return this.usersViewDataService.getDetailsView(openemis_id);
  }

  /**  DETAILS | EDIT  API  **/
  getDetailsEdit(openemis_id) {
    return this.usersViewDataService.getDetailsEdit(openemis_id);
  }

  /**  DETAILS | EDIT  API  **/
  updateUserDetails(openemis_id, payload) {
    return this.usersViewDataService.updateUserDetails(openemis_id, payload);
  }

  /**  ACCOUNTS | VIEW  API  **/
  getAccountsView(openemis_id) {
    return this.usersViewDataService.getAccountsView(openemis_id);
  }
  /**  ACCOUNTS | EDIT  API  **/
  updateAccountDetails(openemis_id, payload) {
    return this.usersViewDataService.updateAccountDetails(openemis_id, payload);
  }
}
