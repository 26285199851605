import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import urls from '../../../../shared/config.urls';

@Injectable()
export class UsersDataService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  /*** Add token to request ***/
  setHeader(): any {
    if (localStorage.getItem('token')) {
      let token = JSON.parse(window.atob(localStorage.getItem('token')));
      let headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
      return { headers: headers };
    } else {
      let headers = new HttpHeaders({});
      return { headers: headers };
    }
  }

  /***  LIST API ***/
  getList(start?: number, end?: number) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${urls.userListing}?start=${start}&end=${end}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /***  LIST | SEARCH API  ***/
  getListSearch(keyword: string, start?: number, end?: number) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${urls.userListing}?start=${start}&end=${end}&keyword=${keyword}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /* Dropdown for gender,identity, nationality type options*/
  getDropdownOptions(entities: Array<string>) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.registration}/${urls.candidate}/dropdown?entities=${entities.join(',')}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /* Dropdown for gender type options*/
  getGenderDropdownOptions(entities) {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.registration}/${urls.candidate}/dropdown?entities=${entities}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /***  ADD USER OpenEMIS Id generate  ***/
  generateOpenEMISID() {
    return this.httpClient
      .get(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${urls.generateOpenEMISNumber}`,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /***  ADD USER API  ***/
  addUser(payload) {
    return this.httpClient
      .post(
        `${environment.baseUrl}/${urls.administration}/${urls.security}/${urls.users}/${urls.userAdd}`,
        payload,
        this.setHeader()
      )
      .pipe(catchError(this.handleError));
  }

  /*** Handle Http Errors ***/
  handleError = (error: HttpErrorResponse | any) => {
    switch (error.status) {
      case 400:
        console.log(400);
        break;
      case 401:
        console.log(401);
        let temp = localStorage.getItem('token');
        if (temp) {
          localStorage.clear();
          this.router.navigate(['/auth']);
        }
        break;
      case 500:
        console.log(500);
        break;
      case 503:
        console.log(503);
        break;
    }
    console.log(error);
    return throwError(error);
  };
}
